import React, {useEffect} from 'react';
import './pagination.scss'

const ClientPagination = (props) => {

    useEffect(() => {
        setPage(props.currentPage)
    }, props.watchList);

    const clickPage = (event) => {
        setPage(parseInt(event.target.dataset.page))
    }

    const setPage = (pageNumber) => {
        props.changePageHandler(pageNumber)

        let from = pageNumber * props.itemsPerPage
        let to = from + props.itemsPerPage
        let pageItems = props.items.slice( from, to )

        props.changeItemsHandler(pageItems)
    }

    const getIsActivePage = (page, currentPage) => {
        if (page === currentPage) {
            return "active"
        } else {
            return null
        }
    }

    const getPaginationLinks = () => {
        let pageCount = Math.ceil(props.items.length / props.itemsPerPage)

        return Array.from({ length: pageCount }).map((_,i) => {
            return <li><a onClick={clickPage} className={getIsActivePage(i, props.currentPage)} data-page={i}>{i + 1}</a></li>
        })
    }

    const getNextLink = () => {
        let pageCount = Math.ceil(props.items.length / props.itemsPerPage)

        if (props.currentPage < pageCount - 1) {
            return <li><a onClick={clickPage} data-page={props.currentPage + 1} role="button">Next</a> &gt;</li>
        } else {
            return null
        }
    }


    const getPreviousLink = () => {
        if (props.currentPage > 0) {
            return <li>&lt; <a onClick={clickPage} data-page={props.currentPage - 1} role="button">Previous</a></li>
        } else {
            return null
        }
    }

    if (props.items && props.items.length > props.itemsPerPage) {
        return (
            <div className="paging">
                <ul>
                    {getPreviousLink()}
                    {getPaginationLinks()}
                    {getNextLink()}
                </ul>
            </div>
        )
    } else {
        return null
    }
}

export default ClientPagination