import * as React from 'react'
import './largeCarousel.scss'
import Carousel from "./carousel";

const LargeCarousel = (props) => {
    return (
        <div className="featured-carousel hero-carousel">
            <div className="featured-container">
                <Carousel slides={props.slides} items={props.items} loop={props.loop} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></Carousel>
            </div>
        </div>
    )
}

export default LargeCarousel