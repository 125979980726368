import React, {useEffect} from 'react';
import {useStaticQuery, graphql, navigate} from 'gatsby'
import NestedSelect from "../global/nestedSelect";
import "./bookNavigation.scss"

const BookNavigation = (props) => {
    const data = useStaticQuery(graphql`
        {
            craft {
                menu: categories(group:"bookTopic", relatedToEntries: {section: "bookPage"}, level: 1) {
                  id
                  title
                  value: slug
                  slug
                  children {
                    id
                    title
                    value: slug
                    slug
                    children {
                      id
                      title
                      value: slug
                      slug
                    }
                  }
                }
            }
        }
    `)

    useEffect(() => {
        let activeMenuItem = document.querySelector('.book-navigation a.active')
        if (activeMenuItem) {
            menuSelectedWalker(activeMenuItem)
        }
    })

    const menuSelectedWalker = (node) => {
        let ul = node.closest('ul')
        let parent = (ul) ? ul.parentNode : false

        if (parent) {
            Array.from(node.closest('ul').parentNode.children).some(element => {
                if (element.className.split(" ").indexOf("nav-parent") > -1) {
                    element.classList.add('active')
                    menuSelectedWalker(element)
                    return true
                }
            })
        }
    }

    const getLink = (node) => {
        if (node.slug === props.active) {
            return (
                <a className="active">{node.title}</a>
            )
        } else {
            if (node.children.length > 0) {
                return (
                    <a onClick={toggleSubMenu} className="nav-parent" role="button">{node.title}</a>
                )
            } else {
                return (
                    <a href={`/books/${node.slug}`}>{node.title}</a>
                )
            }
        }
    }

    const toggleSubMenu = (event) => {
        event.preventDefault();
        event.target.classList.toggle("active")
    }

    const navWalker = (nodes) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        return nodes.map(node => {
            if (node.children.length > 0) {
                return <li key={node.id}>
                    {getLink(node)}
                    <ul>{ navWalker(node.children) }</ul>
                </li>
            } else {
                return <li key={node.id}>{getLink(node)}</li>
            }
        })
    }

    const getMenuItems = () => {
        let items = data.craft.menu

        if (!items.some(item => item.id === null)) {
            items.unshift({
                id: null,
                title: "All Topics",
                value: "",
                slug: "",
                children: []
            })
        }

        return items
    }

    const changeNavigationItem = (event) => {
        navigate(`/books/${event.target.value}`)
    }

    return (
        <div className="book-navigation">
            <h3>TOPICS</h3>
            <NestedSelect changeHandler={changeNavigationItem} classes="mobile" options={getMenuItems()} active={props.active} hasGroups={false}></NestedSelect>
            <ul className="desktop">{ navWalker(data.craft.menu) }</ul>
        </div>
    )
}

export default BookNavigation