import * as React from 'react'
import loadable from '@loadable/component'
import '../owl-carousel/scss/owl.carousel.scss'
import ImageLink from './imageLink'
import './carousel.scss'
import Image from "./image";

const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });

const Carousel = (props) => {
    const getSlides = (nodes) => {
        return nodes.map((node, i) => {
            if (node.imageLink && node.imageLink.length > 0) {
                return <ImageLink src={node.image[0].url} alt={node.image[0].altText} to={node.imageLink[0].linkUrl} openInNewTab={node.imageLink[0].openInNewTab} key={i} ></ImageLink>
            } else {
                return <Image src={node.image[0].url} alt={node.image[0].altText} key={i} />
            }
        })
    }

    return (
        <OwlCarousel items={props.items} loop={props.loop} autoplay={props.autoplay} autoplayTimeout={props.autoplayTimeout} autoplayHoverPause={props.autoplayHoverPause}>
            { getSlides(props.slides) }
        </OwlCarousel>
    )
}

export default Carousel