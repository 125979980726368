import React, {useState} from "react"
import {graphql} from "gatsby";
import BookNavigation from "../components/book/bookNavigation";
import ClientPagination from "../components/global/clientPagination";
import ImageLink from "../components/global/imageLink"
import InnerLayout from "../components/global/innerLayout"
import LargeCarousel from "../components/global/largeCarousel";
import PageTitle from "../components/global/pageTitle";
import Select from "../components/global/select"
import defaultFrontCover from "../images/coming-soon-front-cover.png"
import './books.scss'

export const query = graphql`
    query($currentCategoryId: [Craft_QueryArgument], $publishedDateFilter: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "booksIndex") {
          ... on Craft_booksIndex_booksIndex_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            pageTitle
            booksIndexHeroCarousel {
              ... on Craft_booksIndexHeroCarousel_slide_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websitePublishingCarousel_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
        books: entries(section: "bookPage", relatedToCategories: {id: $currentCategoryId}, publishedDate: $publishedDateFilter, orderBy: "publishedDate desc") {
          ... on Craft_bookPage_default_Entry {
            id
            slug
            title
            bookTitlePrepend
            publishedDate
            bookCategory {
                id
            }
            bookFrontCover {
              key: id
              url(transform: "bookCoverLarge")
              ... on Craft_websiteBookPage_Asset {
                altText
              }
            }
          }
        }
        currentTopic: category(group:"bookTopic", id: $currentCategoryId) {
            id
	        title
	        slug
        }
      }
    }
`

const Books = ({ data, pageContext }) => {

    let books = data.craft.books
    let itemsPerPage = 12

    const [bookQuery, setBookQuery] = useState(null)
    const [sort, setSort] = useState('date')
    const [filter, setFilter] = useState('new')
    const [paginatedBooks, setPaginatedBooks] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const getBookFrontCover = (node) => {
        if (node.length > 0) {
            return node[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getBookFrontCoverAltText = (node) => {
        if (node.length > 0) {
            return node[0].altText
        } else {
            return "Front Cover Image Coming Soon"
        }
    }

    const renderBooks = () => {

        if (paginatedBooks) {
            if (paginatedBooks.length > 0) {
                return paginatedBooks.map(book => (
                    <div className="books-list-post">
                        <div className="latest-post-img">
                            <ImageLink to={`/book/${book.slug}`} openInNewTab={false} src={getBookFrontCover(book.bookFrontCover)} alt={getBookFrontCoverAltText(book.bookFrontCover)}/>
                        </div>
                        <h4><a href={`/book/${book.slug}`}>{book.bookTitlePrepend} {book.title}</a></h4>
                    </div>
                ))
            } else {
                return <p>No books matching your selection were found</p>
            }
        } else {
            if (books.length > 0) {
                return books.slice(0, itemsPerPage).map(book => (
                    <div className="books-list-post">
                        <div className="latest-post-img">
                            <ImageLink to={`/book/${book.slug}`} openInNewTab={false} src={getBookFrontCover(book.bookFrontCover)} alt={getBookFrontCoverAltText(book.bookFrontCover)}/>
                        </div>
                        <h4><a href={`/book/${book.slug}`}>{book.bookTitlePrepend} {book.title}</a></h4>
                    </div>
                ))
            } else {
                return <p>No books matching your selection were found</p>
            }
        }
    }

    const filterBooks = (books) => {
        // Filter by date
        if (filter === "old") {
            books = books.filter(book => {
                return new Date(book.publishedDate) < new Date("06/01/2008")
            })
        } else {
            books = books.filter(book => {
                return new Date(book.publishedDate) >= new Date("06/01/2008")
            })
        }

        // Filter by title
        if (bookQuery) {
            books = books.filter(book => {
                return book.title.toLowerCase().indexOf(bookQuery.toLowerCase()) > -1
            })
        }

        // Sort - If not set, fallback to date dsc which is what the query already does
        if (sort === "alpha") {
            books = books.sort((a,b) => {
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
                return 0;
            })
        }

        return books
    }

    const changeSort = (event) => {
        setSort(event.target.value)
        setCurrentPage(0)
    }

    const changeFilter = (event) => {
        setFilter(event.target.value)
        setCurrentPage(0)
    }

    const searchBooks = (event) => {
        let query = event.target.value

        if (query) {
            setBookQuery(query)
        } else {
            setBookQuery(false)
        }
        setCurrentPage(0)
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd books-page">
                <PageTitle title={data.craft.page.pageTitle}/>

                <div className="content-page">
                    <div className="container-small">
                        <LargeCarousel slides={data.craft.page.booksIndexHeroCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <div className="left-menu-block book-menu-side">
                                    <h3>BOOKS</h3>
                                    <ul>
                                        <li><a href="/books" className="active">All Books</a></li>
                                        <li><a href="/books/upcoming">Upcoming Releases</a></li>
                                    </ul>
                                </div>
                                <div className="left-menu-block">
                                    <BookNavigation active={pageContext.currentCategoryId ? data.craft.currentTopic.slug : ""} />
                                </div>
                            </div>
                            <div className="content-right">
                                <h3>All Books{pageContext.currentCategoryId && data.craft.currentTopic.title ? ` > ${data.craft.currentTopic.title}` : null}</h3>

                                <div className="mobile-books-menu">
                                    <a href="/books" className="active">All Books</a> | <a href="/books/upcoming">Upcoming Releases</a>
                                </div>

                                <div className="search-block">
                                    <input id="book-search" type="text" placeholder="Search for a Book Title" onChange={searchBooks}/>
                                </div>

                                <div className="filters">
                                    <div className="filter-column">
                                        <Select changeHandler={changeSort} classes="sort-select" options={[
                                            {
                                                title: "Most Recent",
                                                value: "date",
                                                selected: true
                                            }, {
                                                title: "Alphabetical",
                                                value: "alpha"
                                            }
                                        ]}></Select>
                                    </div>
                                    <div className="filter-column">
                                        <Select changeHandler={changeFilter} classes="filter-select" options={[
                                            {
                                                title: "2008 - Present",
                                                value: "new",
                                                selected: true
                                            }, {
                                                title: "1993 - 2008",
                                                value: "old"
                                            },
                                        ]}></Select>
                                    </div>
                                </div>

                                <div className="books-list">
                                    {renderBooks()}
                                </div>

                                <ClientPagination items={filterBooks(books)} watchList={[currentPage, bookQuery, sort, filter]} currentPage={currentPage} itemsPerPage={itemsPerPage} changePageHandler={setCurrentPage} changeItemsHandler={setPaginatedBooks} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Books